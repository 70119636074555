import { inject, Injectable } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { foundValidUserSession } from '@frontend/data-access/user/authentication';
import { getAllChatTags } from '@frontend/data-access/user/chat-tags';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InternalAgentDto } from '@shared/internal-api-interface';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getProfile, getProfileFailure, getProfileSuccess } from './profile.actions';
import { ProfileService } from './profile.service';
import { mapAgentDtoToProfile } from './profile.utils';

@Injectable()
export class ProfileEffects {
    private readonly actions$ = inject(Actions);
    private readonly profileService = inject(ProfileService);

    getProfileWhenValidSessionExists$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foundValidUserSession),
            map(() => getProfile()),
        );
    });

    getProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProfile),
            switchMap(() => {
                return this.profileService.getProfile().pipe(
                    map((agentDto: InternalAgentDto) => {
                        return getProfileSuccess({
                            profile: mapAgentDtoToProfile(agentDto),
                        });
                    }),
                    catchError((error: Error) => of(getProfileFailure({ error }))),
                );
            }),
        );
    });

    getProfileSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProfileSuccess),
            switchMap(() => [getAllChatTags(), routeTo({ commands: ['agent-chat'] })]),
        );
    });
}
