import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig, ProjectEnvironment } from '@frontend/configuration';
import { APP_VERSION_NUMBER } from '@shared/constants';
import * as SentryAngular from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';

@Injectable({ providedIn: 'root' })
export class SentryService {
    private readonly environment = inject<BaseEnvironmentConfig | undefined>(BASE_ENVIRONMENT_CONFIG);
    private ZIGZAG_ENDPOINTS = [
        'localhost',
        /^https:\/\/puppito-domain-api-prod\.azurewebsites\.net\/api/,
        /^https:\/\/api\.internal\.zigzag\.dog\/\/api/,
    ];

    public async initSupportPortalSentry() {
        SentryAngular.init({
            dsn: 'https://e8b0a21df27a9bb091670b5f8fc42d4c@o4506677667168256.ingest.sentry.io/4506678161965056',
            release: '1.0.0',
            environment: this.environment?.environment ?? 'Unknown',
            tracesSampleRate: this.environment?.environment === ProjectEnvironment.PRODUCTION ? 0.1 : 1.0,
            tracePropagationTargets: this.ZIGZAG_ENDPOINTS,
            replaysSessionSampleRate: this.environment?.environment === ProjectEnvironment.PRODUCTION ? 0.05 : 0.1,
            replaysOnErrorSampleRate: this.environment?.environment === ProjectEnvironment.PRODUCTION ? 0.1 : 0.5,
        });
    }

    public async initMobileAppSentry(platform: string) {
        Sentry.init(
            {
                dsn: 'https://333f85cd489f1e3ac2bf0b47a7f6e87c@o4506677667168256.ingest.sentry.io/4506677669330944',
                release: `zigzag@${APP_VERSION_NUMBER}`,
                dist: platform,
                environment: this.environment?.environment ?? 'Unknown',
                tracesSampleRate: 0.01,
                tracePropagationTargets: this.ZIGZAG_ENDPOINTS,
                integrations: [
                    // Registers and configures the Tracing integration,
                    // which automatically instruments your application to monitor its
                    // performance, including custom Angular routing instrumentation
                    Sentry.browserTracingIntegration(),
                    // Uses the rewrite frames integration as suggested by Sentry docs
                    // to better match source maps to minified code for error debugging
                    SentryAngular.rewriteFramesIntegration(),
                    Sentry.replayIntegration(),
                ],
            },
            SentryAngular.init,
        );
    }

    public async setUserMobileAppSentry({ userId, email }: { userId: string; email: string }) {
        Sentry.setUser({
            id: userId,
            email,
        });
    }
}
